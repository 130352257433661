@import "variables";

body {
  background: $white-back;
  font-family: $font-primary;
  color: $primary;
  margin: 0;
}

* {
  box-sizing: border-box;
}

html, body {
  height: 100%;
  -webkit-overflow-scrolling: touch;
  font-size: 16px;

  @include breakpoint($lg) {
    font-size: 16px;
  }
  @include breakpoint($md) {
    font-size: 15px;
  }
  @include breakpoint($sm) {
    font-size: 14px;
  }
  @include breakpoint($xs) {
    font-size: 14px;
  }
}

::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  border-radius: 8px;
}

.w-0 {
  width: 0 !important;
}

.h-0 {
  height: 0 !important;
}

.w-full {
  width: 100vw !important;
}

.h-full {
  height: 100vh !important;
}

.align-items-center {
  align-items: center;
}

a:hover {
  text-decoration: none;
}

.justify-content-center {
  justify-content: center;
}

.d-flex {
  display: flex !important;
}

.d-flex-center {
  @extend .d-flex;
  @extend .align-items-center;
  @extend .justify-content-center;
}

.flex-column {
  flex-direction: column;
}

.justify-content-between {
  justify-content: space-between;
}

.outline-none {
  outline: none !important;
}

.pointer-event-none {
  pointer-events: none !important;
}

*:not(button, input) {
  outline: none;
}

.wrapper-page {
  margin: 5% auto;
  position: relative;
  width: 420px;

  @include breakpoint($sm) {
    width: 90%;
  }
}

.card-box {
  padding: 2.5rem;
  background: white;
  border-radius: 0.5rem;
  box-shadow: 0 1.5625rem 2.5rem 0 rgba($primary, 0.05);
}

.panel-body {
  padding: 15px;
}

.back-drop {
  position: absolute;
  width: 100%;
  background: #0008;
  color: white;
  z-index: 10;
}

.modal {
  overflow: auto;

  @include breakpoint($md) {
    .modal-lg {
      max-width: 80% !important;
    }
  }
}

.tooltip {
  background: white;
  color: black;
  font-size: 12px;
  padding: 2px 5px;
  border: 1px solid black;
  min-width: max-content;
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.06), 0 1px 0 0 rgba(0, 0, 0, 0.02);
  left: -5px;
  top: 105%;
  display: none;
}

.spinner {
  width: 100vw;
  height: 100vh;
  position: fixed;
  flex-direction: column;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  z-index: 100000;
  background: #fff8;

  img {
    width: 50px;
  }
}

.note-popover {
  display: none;
}

.note-toolbar {
  .note-btn-group .note-btn {
    background: white;
  }

  .note-font, .note-para {
    .note-icon-caret {
      display: none;
    }
  }

  .note-color {
    .dropdown-toggle::after {
      display: none;
    }

    .dropdown-menu {
      width: 344px;

      .btn-group {
        display: inline-block;
        padding-left: 6px;
        padding-right: 3px;

        .note-palette-title {
          text-align: center;
          border-bottom: 1px solid #ddd;
          padding-bottom: 3px;
          margin: 3px 5px 0 5px;
        }

        .note-color-reset {
          width: 100%;
          border-radius: 10px;
          background: white;
          padding: 2px;
          border: 1px solid #ccc;
          margin: 8px 0 5px;
        }
      }
    }
  }

  .dropdown-fontsize, .dropdown-line-height {
    a {
      color: #212529;
    }

    .note-icon-check:before {
      content: "";
      color: #007bff;
      opacity: 0;
    }

    a.checked {
      .note-icon-check:before {
        opacity: 1;
      }
    }
  }
}

.require-mark {
  color: $red;
  font-weight: bold;
}

.dropdown-item {
  cursor: pointer;
}

.badge {
  color: black;

  &.yellow {
    background-color: $yellow;
  }
  &.purple {
    background-color: $purple;
  }
  &.sky {
    background-color: $sky;
  }
  &.pink {
    background-color: $pink;
  }
  &.blue {
    background-color: $blue;
  }
  &.green {
    background-color: $green;
  }
  &.red {
    background-color: $red;
  }
}

.user-svg {
  width: 18px;
  cursor: pointer;

  &.fade {
    opacity: 0.4;

    &:hover {
      opacity: 1;
    }
  }

  &.white {
    filter: invert(1);
  }
}

.note-editor {
  .note-popover .popover-content .dropdown-menu.note-check li a i, .panel-heading.note-toolbar .dropdown-menu.note-check li a i {
    visibility: unset;
  }

  .note-btn-group {
    flex-wrap: wrap;
    justify-content: flex-start;

    .note-btn {
      flex: unset;
    }
  }
}

body .fr-fullscreen-wrapper{
  z-index: 5000 !important;
}

.cursor-pointer {
  cursor: pointer !important;
}

.link {
  cursor: pointer;

  &.text-primary:hover {
    color: #0056b3 !important;
  }
}

img.ReactCrop__image {
  min-width: 150px;
}

.text-ellipsis {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.hover-underline:hover {
  text-decoration: underline !important;
}

.text-link {
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
}

.text-capitalize {
  text-transform: capitalize !important;
}
