
@font-face {
  font-family: ProximaNova;
  src: url('/resouces/fonts/ProximaNova/Proxima Nova Bold.otf') format('truetype');
  font-weight: bold;
}
@font-face {
  font-family: ProximaNova;
  src: url('/resouces/fonts/ProximaNova/Proxima Nova Condensed Bold.otf') format('truetype');
  font-weight: bold;
  font-stretch: condensed;
}
@font-face {
  font-family: ProximaNova;
  src: url('/resouces/fonts/ProximaNova/Proxima Nova Extra Condensed Bold.otf') format('truetype');
  font-weight: bold;
  font-stretch: extra-condensed;
}
@font-face {
  font-family: ProximaNova;
  src: url('/resouces/fonts/ProximaNova/ProximaNova-Regular.otf') format('truetype');
  font-weight: normal;
}
@font-face {
  font-family: ProximaNova;
  src: url('/resouces/fonts/ProximaNova/Proxima Nova Alt Light.otf') format('truetype');
  font-weight: 300;
}
@font-face {
  font-family: ProximaNova;
  src: url('/resouces/fonts/ProximaNova/Proxima Nova Condensed Light.otf') format('truetype');
  font-weight: 300;
  font-stretch: condensed;
}
@font-face {
  font-family: ProximaNova;
  src: url('/resouces/fonts/ProximaNova/Proxima Nova Extra Condensed Light.otf') format('truetype');
  font-weight: 300;
  font-stretch: extra-condensed;
}

h1, h2, h3, h4, h5, h6 {
  font-weight: bold;
  margin: 0;
}

h1 {
  font-size: 2.25rem;
}

h2 {
  font-size: 1.5rem;
}

h6 {
  font-size: 0.875rem;
}

.text-xs {
  font-size: 0.75rem !important;
}

.text-sm {
  font-size: 0.875rem !important;
}

.text-base {
  font-size: 1rem !important;
}

.text-lg {
  font-size: 1.125rem !important;
}

.text-xl {
  font-size: 1.25rem !important;
}

.text-2xl {
  font-size: 1.5rem !important;
}

.text-3xl {
  font-size: 1.75rem !important;
}

.text-4xl {
  font-size: 2rem !important;
}

@for $i from 1 through 100 {
  .text-#{$i}p {
    font-size: $i * 0.0625rem !important;
  }
}
