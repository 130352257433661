@import "src/scss/variables";

.scrum-preview-modal {
  position: fixed;
  width: 100vw;
  height: 100vh;
  z-index: 1050;
  top: 0;
  left: 0;

  .backdrop {
    position: absolute;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    background: #0008;
  }

  .modal-content {
    position: relative;
    width: 90vw;
    height: 90vh;
    margin: 5vh 5vw;
    background: #f3f8fc;
    box-shadow: 0 1px 3px #444;
    padding: 20px 0;
    overflow: auto;

    .media-preview {
      max-height: 100%;

      img, video {
        max-width: 95%;
        max-height: 95%;
        width: unset !important;
        height: unset !important;
      }
    }

    .media-info {
      padding: 20px;

      .status-icon {
        width: 38px;
        min-width: 38px;
        height: 38px;
        border-radius: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: $red;
        color: white;
        font-size: 17px;

        &.in-development {
          background: $blue;
        }

        &.accepted {
          background: $green;
        }
      }

      .media-title {
        font-size: 16px;
        color: #222;
      }

      .section-title {
        font-weight: bold;
        color: #01497e;
      }

      hr {
        border-color: #e1e9f2;
      }

      .media-desc {
        border: 0;
        width: 100%;
      }

      .media-desc * {
        font-size: 14px !important;
      }
    }
  }

  .action-button {
    position: absolute;
    color: white;
    opacity: 0.6;
    padding: 10px;
    font-size: 3rem;
    z-index: 15;
    cursor: pointer;

    &:hover {
      opacity: 1;
    }
  }

  .btn-prev {
    top: 50%;
    left: 10px;
  }

  .btn-next {
    top: 50%;
    right: 10px;
  }

  .close-button {
    position: absolute;
    right: 10px;
    top: 10px;
    font-size: 1.5rem;
    color: #01497e;
    cursor: pointer;
  }

  .media-feedback {

    h5 {
      font-size: 1rem;
      color: #01497e;
    }
  }
}
