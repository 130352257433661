@import "src/scss/variables";

.project-media {
  width: 240px;
  margin: 10px;
  background: white;

  &.active {

    .media-preview {
      border-color: $primary;
    }
  }

  .media-preview {
    position: relative;
    overflow: hidden;
    width: 100%;
    height: 150px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    background-clip: padding-box;
    background: $white-back;
    border: 2px solid transparent;

    img {
      max-width: 100%;
      max-height: 100%;
      filter: drop-shadow(0px 2px 2px #0008);
    }

    .checkbox {
      position: absolute;
      bottom: 0;
      right: 0;
    }

    &:not(:hover) {

      .checkbox:not(.Mui-checked) {
        display: none;
      }
    }
  }

  .media-title {
    padding: 10px 0;

    a {
      font-size: 0.875rem;
      color: $gray;
      cursor: pointer;
      transition: all ease 0.3s;

      &:hover {
        color: $primary;
      }
    }
  }

  .video-frame {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;

    .video-frame-img {
      position: absolute;
      width: 50px;
    }
  }
}
