@import "src/scss/variables";

.tab-screenshot {

  .search-box {
    background: #fafafa;
    border-radius: 5px !important;
    box-shadow: none;

    .MuiAccordionSummary-root {
      height: 3rem !important;
      min-height: 3rem !important;

      &.Mui-expanded {
        border-bottom: 1px solid rgba($secondary, 0.5);
      }

      .MuiButtonBase-root.MuiIconButton-root {
        border: 1px solid $secondary;
        background: white;
        width: 1.75rem;
        height: 1.75rem;
        color: $gray !important;
      }

      .MuiAccordionSummary-expandIcon.Mui-expanded {
        transform: rotate(90deg);
      }
    }

    .MuiAccordionDetails-root {
      padding: 1rem 1rem 0;
    }

    .form-control-wrapper {
      margin-bottom: 0.25rem;
    }
  }
}
