@import "src/scss/variables";

.project-item-wrapper {
  position: relative;
  border-radius: 6px;
  transition: background-color ease-in-out 0.2s;

  .project-item {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 7px 30px 7px 15px;
    cursor: default;
    color: white;
    height: 2.75rem;
    box-sizing: border-box;

    .project-logo {
      width: 30px;
      height: 30px;
      border: 1px solid #eee;
      border-radius: 20%;
      padding: 2px;
      position: relative;

      .logo-container {
        overflow: hidden;
        border-radius: 20%;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;

        img {
          max-width: 100%;
          max-height: 100%;
        }

        i {
          font-size: 18px;
        }
      }
    }

    .project-name {
      flex: 1;
      font-size: 1.125rem;
      padding: 0 10px;
      opacity: 0.75;
    }

    .badge {
      background: $warning;
      color: $primary;
      margin-right: 5px;
    }

    .report-icon {
      color: $muted;

      &.active {
        color: $warning;
      }
    }
  }

  .project-actions {
    position: absolute;
    right: 10px;
    top: 6px;
    padding: 5px;
    color: white;
    cursor: pointer;

    &:hover {
      .actions-menu {
        right: -5px;
      }
    }

    .actions-menu {
      width: 70px;
      position: absolute;
      display: flex;
      justify-content: space-around;
      align-self: center;
      right: -125px;
      top: 0;
      padding: 7px;
      background: $primary-dark;
      border: 1px solid $gray;
      border-radius: 10px;
      transition: all ease-in 0.2s;

      i {
        padding: 0 2px;
        cursor: pointer;
        color: #aaa;
      }

      .fa-edit:hover {
        color: $green;
      }

      .fa-trash:hover {
        color: $red;
      }

      .fa-external-link:hover {
        color: $sky;
      }
    }
  }

  &.active {
    background: $warning;

    .project-logo {
      color: $primary;
      border: 1.5px solid $sky;
    }

    .project-name {
      color: $primary;
      font-weight: bold;
      opacity: 1;
    }

    .menu-button {
      color: $primary;
    }

    .badge {
      background: $primary;
      color: $warning;
    }

    .report-icon {

      &.active {
        color: $primary;
      }
    }
  }

  &:hover {

    .project-name {
      opacity: 1;
    }

    .project-logo {
      box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.06), 0 1px 0 0 rgba(0, 0, 0, 0.02);
    }
  }
}

.MuiTooltip-tooltip.project-tooltip {
  background: $primary-dark;
  width: 15rem;
  padding: 10px 20px;
  margin-top: 5px;
  border-radius: 10px;
  filter: drop-shadow(0 0 1px #aaa);

  .MuiTooltip-arrow::before {
    background: $primary-dark;
  }

  p {
    margin-bottom: 5px;
    font-size: 0.75rem;
    color: #eee;
    display: flex;

    span {
      display: inline-block;
      color: #aaa;
      font-weight: bold;
      min-width: 40px;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }
}
