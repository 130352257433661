@import "src/scss/variables";

.simple-layout {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  color: $primary;

  .layout-header {
    background: white;
    position: relative;
    z-index: 10;
  }

  .layout-content {
    width: 100%;
    height: 0;
    flex: 1;
    overflow: auto;
  }
}
