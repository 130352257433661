@import "src/scss/variables";

.full-layout {
  width: 100vw;
  height: 100vh;
  display: flex;
  background: white;

  .drawer-content {
    background: $white-back;
  }
}
