@import "src/scss/variables";

.create-weekly-report-modal {

  .drop-zone {
    display: block;
    position: relative;
    cursor: pointer;
    overflow: hidden;
    width: 100%;
    max-width: 100%;
    height: 200px;
    padding: 5px 10px;
    font-size: 14px;
    line-height: 22px;
    color: #777;
    background-color: #FFF;
    background-image: none;
    text-align: center;
    border: 2px solid #E5E5E5;

    .dropify-message {
      position: relative;
      top: 50%;
      transform: translateY(-50%);

      i {
        font-size: 50px;
        color: #CCC;
      }

      p {
        margin: 5px 0 0;
        line-height: 1.6;
      }
    }

    .audio {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;

      .image {
        max-width: 100%;
        filter: drop-shadow(2px 4px 6px #0008);
      }

      .change-audio {
        padding: 10px;
        bottom: -50px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        flex-wrap: wrap;
        overflow: auto;
        transition: all ease-in 0.2s;
      }

      &:hover {
        img {
          filter: blur(2px);
        }

        .change-audio {
          bottom: 0;
        }
      }
    }
  }

  .remove-audio {
    color: #0f4bac;
    text-decoration: underline;
    cursor: pointer;
    margin-top: 5px;

    &:hover {
      color: navy;
    }
  }
}
