@import "src/scss/variables";

.sidebar .client-menu {
  transition: all ease-in-out 0.3s;

  .menu-bar {
    height: 2.75rem;
    padding: 0.625rem 0.2rem 0.625rem 0.625rem;
    border-radius: 6px;
    color: white;
    cursor: pointer;
    transition: all ease-in-out 0.2s;

    .client-info {

      svg {
        width: 0.875rem;
        margin: 0 0.75rem 0 0.375rem;
      }

      span {
        max-width: 9rem;
      }
    }

    .menu-actions {

      .icon-edit {
        width: 14px;
        opacity: 0.5;

        &:hover {
          opacity: 1;
        }
      }

      .chevron {
        transition: all ease-in-out 0.2s;
      }
    }
  }

  .project-list {
    padding-left: 1rem;
    transition: all ease-in-out 0.3s;
  }

  &.active {

    .client-info {
      font-weight: bold;
    }
  }

  &:not(.active) {
    opacity: 0.75;
  }

  &:hover {
    opacity: 1;
  }

  &.active:not(.opened) {

    .menu-bar {
      background: #415764;
    }
  }

  &.opened {

    .menu-bar {

      .chevron {
        transform: rotate(90deg);
      }
    }
  }

  &.collapsed {
    overflow: hidden;
    max-height: 2.75rem !important;

    .project-list {
      opacity: 0;
    }
  }
}
