@import "src/scss/variables";

.past-versions {

  .history-item {

    .image-container {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100px;
      cursor: zoom-in;

      img, video {
        width: auto;
        max-width: 100%;
        max-height: 100%;
      }
    }
  }
}
