@import "src/scss/variables";

.auth-layout {
  width: 100vw;
  min-height: 100vh;
  background-size: cover;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  .logo {
    position: absolute;
    top: 35px;
    left: 25px;
    width: auto;
    height: 35px;
  }
}
